/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1aa67d52-8934-4af1-882c-4e47650c3fcd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_saYNu8QV9",
    "aws_user_pools_web_client_id": "2derjv6ofqqmaf7o73h1rg3uct",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://wezwad36yfaeldqu32j7kxtv6a.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-i5ngqq6vmrdxjnqmxu2qfqe3da",
    "aws_user_files_s3_bucket": "amplifybucket174230-test",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
